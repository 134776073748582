@mixin screen($breakpoint-key) {
  @if map-has-key($map: $breakpoints, $key: $breakpoint-key) {
    @media screen and (min-width: map-get($map: $breakpoints, $key: $breakpoint-key)) {
      @content;
    }
  }
}
@mixin gradient-bg($gradient1, $gradient2, $gradient3, $child: null) {
  @if $child == null {
    &:nth-child(3n + 1) {
      background: $gradient1;
    }

    &:nth-child(3n + 2) {
      background: $gradient2;
    }

    &:nth-child(3n + 3) {
      background: $gradient3;
    }
  } @else {
    &:nth-child(3n + 1) #{$child} {
      background: $gradient1;
    }

    &:nth-child(3n + 2) #{$child} {
      background: $gradient2;
    }

    &:nth-child(3n + 3) #{$child} {
      background: $gradient3;
    }
  }
}

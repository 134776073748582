@import '/src/styles/variables';
@import '/src/styles/mixins';

.header {
  box-shadow: $commonBoxShadow;
  margin-bottom: 5rem;

  &__content {
    position: relative;
    z-index: 99;
    text-align: left;
    background: $gradient1;

    &.fixed {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
    }
  }

  .menu-link {
    display: inline-block;
    cursor: pointer;
    margin: 2rem;
    color: $primary-color;
    transition: color 0.3s;

    @include screen(md) {
      margin-left: 8rem;
    }

    &:hover {
      color: $gradient3;
    }
  }
}

@import '/src/styles/variables';
@import '/src/styles/mixins';

$radius: 1rem;

#about {
  .about__content {
    &-main,
    &-skills {
      min-height: 30rem;
      margin: 2rem;
      border-radius: $radius;
      box-shadow: $commonBoxShadow;
      padding: 2rem;
    }

    &-main {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      .btn-wrapper {
        @include screen(lg) {
          padding: 1rem 5rem;
        }

        .round__btn {
          width: 10rem;
          height: 10rem;

          &--text {
            letter-spacing: 2px;
            font-size: 1.3rem;
          }
        }
      }
    }

    &-details {
      .text-primary {
        line-height: 3rem;

        @include screen(sm) {
          line-height: 4rem;
        }

        @include screen(lg) {
          line-height: 5rem;
        }
      }
    }

    &-title {
      font-weight: 700;
      font-size: 2.2rem;
      text-align: center;
      margin-bottom: 2.5rem;
      margin-top: 2.5rem;
    }

    &-skills {
      .skills {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 5rem;

        &__skill {
          margin: 1rem;
        }

        span {
          display: inline-block;
        }
      }
    }
  }
}

@import 'variables';

.nav-links-wrapper {
  text-align: center;
}

.nav-link {
  display: inline-block;

  &:hover {
    animation: rubberBand;
    animation-duration: 1s;
  }
}

.fixed {
  position: fixed !important;
}

.round__btn {
  display: inline-block;
  position: relative;
  width: 12rem;
  height: 12rem;
  box-shadow: $circleBoxShadow;
  border-radius: 50%;
  @include gradient-bg($gradient3, $gradient1, $gradient2);

  @include screen(sm) {
    width: 15rem;
    height: 15rem;
  }

  @include screen(md) {
    width: 20rem;
    height: 20rem;
  }

  &--text {
    position: absolute;
    top: 50%;
    right: 0;
    left: 0;
    transform: translateY(-50%);
    color: $primary-color;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 700;
    font-size: 1.3rem;
    text-align: center;

    @include screen(sm) {
      letter-spacing: 4px;
      font-size: 1.6rem;
    }
  
    @include screen(md) {
      letter-spacing: 6px;
      font-size: 1.9rem;
    }
  }
}

.elipse__btn {
  display: inline-block;
  position: relative;
  width: 10rem;
  height: 5rem;
  box-shadow: $circleBoxShadow;
  border-radius: 50%;
  @include gradient-bg($gradient3, $gradient1, $gradient2);

  &--text {
    position: absolute;
    top: 50%;
    right: 0;
    left: 0;
    transform: translateY(-50%);
    color: $primary-color;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 700;
    font-size: 1.3rem;
    text-align: center;
  }
}

.heading-primary {
  color: $secondary-color;
  font-size: 3.7rem;
  text-transform: uppercase;
  letter-spacing: 3px;
  line-height: 2em;
  text-align: center;

  @include screen(sm) {
    font-size: 4rem;
  }

  margin-bottom: 2.5rem;
  @include screen(lg) {
    font-size: 4.7rem;
    margin-bottom: 3.5rem;
  }
}

.heading-secondary {
  color: $secondary-color;
  margin-bottom: 10rem;
  @include screen(lg) {
    margin-bottom: 12rem;
  }

  &-main {
    display: block;
    font-size: 2.7rem;
    text-transform: uppercase;
    letter-spacing: 3px;
    text-align: center;
    line-height: 1.5em;
    margin-bottom: 3.5rem;
    position: relative;

    @include screen(md) {
      font-size: 3.5rem;
      line-height: 2em;
    }

    &::after {
      content: '';
      position: absolute;
      top: calc(100% + 1.2rem);
      height: 5px;
      width: 3rem;
      background: $secondary-color;
      left: 50%;
      transform: translateX(-50%);
      border-radius: $commonBorderRadius;

      @include screen(lg) {
        top: calc(100% + 1.5rem);
      }
    }
  }
}

.text-primary {
  color: $secondary-color;
  margin-bottom: 2.5rem;
  font-size: 1.8rem;
  text-align: justify;
  width: 100%;
  line-height: 1.6;

  @include screen(lg) {
    font-size: 2.2rem;
    margin-bottom: 3.5rem;
    text-align: center;
  }
}

.text-secondary {
  color: $secondary-color;
  margin-bottom: 1rem;
  font-size: 1.2rem;
  text-align: center;
  width: 100%;
  line-height: 1.6;

  @include screen(sm) {
    font-size: 1.7rem;
    margin-bottom: 2rem;
  }
  @include screen(md) {
    font-size: 2rem;
    margin-bottom: 2.5rem;
  }
  @include screen(lg) {
    font-size: 1.7rem;
    margin-bottom: 2rem;
  }
}
